<template>

  <div>
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="9"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h1 class="custom-header-title">
              {{ callLog.stringID }} - {{ callLog.customerName }}
            </h1>
            <p class="log_info">
              Created by {{ callLog.createdBy ? callLog.createdBy.name : '' }} on {{ dateFormatWithTime(callLog.createdAt) }} <br>Last updated on {{ dateFormatWithTime(callLog.updatedAt) }}
            </p>
          </b-col>
        </b-row>
      </b-col>

      <!-- Content Right -->
      <b-col
        class="content-header-right text-md-right d-md-block d-none mb-1"
        md="3"
        cols="12"
      >
        <div class="user_block">
          <div class="user_avatar">
            <img :src="callLog.dutyOfficer ? (callLog.dutyOfficer.image ? callLog.dutyOfficer.image : defaultImage) : defaultImage">
          </div>
          <div class="user_info">
            <h6>Duty Officer</h6>
            <h5>{{ callLog.dutyOfficer ? callLog.dutyOfficer.name : '' }}</h5>
          </div>
          <div class="action_button">
            <b-button
              v-b-modal.modal-update-duty-officer
              variant="flat-primary"
            >
              <feather-icon
                icon="EditIcon"
                class="mr-50"
                size="16"
              />
            </b-button>
          </div>
        </div>
      </b-col>
    </b-row>
    <!-- Table Container Card -->

    <b-card
      class="mb-3 p-2 calllog-details"
    >
      <b-row
        class="content-header"
      >

        <!-- Content Left -->
        <b-col
          class="content-header-left mb-2"
          cols="12"
          md="9"
        >
          <h3>Call Log Details</h3>
        </b-col>

        <b-col
          class="content-header-right text-md-right d-md-block d-none mb-1"
          md="3"
          cols="12"
        >
          <b-button
            variant="flat-primary"
            :to="{ name: 'appointment-call-logs-edit', params: { id: $route.params.id } }"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
              size="16"
            />
            <span class="align-middle">Edit</span>
          </b-button>
        </b-col>
      </b-row>
      <b-table-simple
        borderless
        responsive
      >
        <b-tbody>
          <b-tr>
            <b-th>
              Customer Name
            </b-th>
            <b-td>
              <div class="text-nowrap">
                <b-button
                  v-if="callLog.customer && canViewThisAction('show', 'Customer')"
                  v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                  variant="link"
                  class="weight-600 align-middle detail-view-id text-uppercase"
                  :to="{ name: 'customers-customers-show', params: { id: callLog.customer } }"
                >
                  {{ callLog.customerName }}
                </b-button>
                <span v-else>
                  {{ callLog.customerName }}
                </span>
              </div>
            </b-td>
            <b-th>
              Agent Name
            </b-th>
            <b-td>
              <div class="text-nowrap">
                <b-button
                  v-if="callLog.agent && canViewThisAction('show', 'Agent')"
                  v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                  variant="link"
                  class="weight-600 align-middle detail-view-id text-uppercase"
                  :to="{ name: 'agency-agents-show', params: { id: callLog.agent } }"
                >
                  {{ callLog.agentName || '-' }}
                </b-button>
                <span v-else>
                  {{ callLog.agentName || '-' }}
                </span>
              </div>
            </b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Mobile Number
            </b-th>
            <b-td>{{ callLog.customerContact }}</b-td>
            <b-th>
              Agent Code
            </b-th>
            <b-td>{{ callLog.agentCode || '-' }}</b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Type of Call
            </b-th>
            <b-td>{{ callLog.typeOfCall ? callLog.typeOfCall.name : '' }}</b-td>
            <b-th>
              Created Date
            </b-th>
            <b-td>{{ dateFormatWithTime(callLog.createdAt) }}</b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Nature of Call
            </b-th>
            <b-td>{{ callLog.natureOfCall ? callLog.natureOfCall : '' }}</b-td>
            <b-th>
              Created By
            </b-th>
            <b-td>{{ callLog.dutyOfficer ? callLog.dutyOfficer.name : '' }}</b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Details and Remarks
            </b-th>
            <b-td>{{ callLog.remarks ? callLog.remarks : '' }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
      <hr class="dividerHR hrDbMR">

      <b-row
        class="content-header"
      >

        <!-- Content Left -->
        <b-col
          class="content-header-left mb-2"
          cols="12"
          md="12"
        >
          <h3>Related Record</h3>
        </b-col>
      </b-row>

      <b-table-simple
        v-if="callLog.booking || callLog.serviceForm"
        ref="relatedCallLogsTable"
        class="position-relative has_padding"
        responsive
        primary-key="_id"
        show-empty
        no-sort-reset
        empty-text="No matching records found"
      >
        <b-thead>
          <b-tr>
            <b-th>ID</b-th>
            <b-th>Record Name</b-th>
            <b-th>Activity Type</b-th>
            <b-th>created date</b-th>
            <b-th>created by</b-th>
            <b-th>actions</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr>
            <b-td>
              <div v-if="callLog.booking">
                <b-button
                  v-if="canViewThisAction('show', 'Booking')"
                  variant="link"
                  class="weight-600 align-middle detail-view-id"
                  :to="{ name: 'appointment-bookings-show', params: { id: callLog.booking._id || 0 } }"
                >
                  {{ callLog.booking.stringID }}
                </b-button>
                <b-button
                  v-else
                  variant="none"
                  class="text-bold-black align-middle detail-view-id not-button"
                >
                  {{ callLog.booking.stringID }}
                </b-button>
              </div>
              <div v-else>
                <b-button
                  v-if="canViewThisAction('show', 'ServiceForm')"
                  variant="link"
                  class="weight-600 align-middle detail-view-id"
                  :to="{ name: 'operation-service-forms-show', params: { id: callLog.serviceForm._id || 0 } }"
                >
                  {{ callLog.serviceForm.stringID }}
                </b-button>
                <b-button
                  v-else
                  variant="none"
                  class="text-bold-black align-middle detail-view-id not-button"
                >
                  {{ callLog.serviceForm.stringID }}
                </b-button>
              </div>
            </b-td>
            <b-td>
              <div v-if="callLog.booking">
                <span class="">
                  {{ callLog.booking.activityType.typeName }}  -
                  {{ callLog.booking.customerName }}, {{ dateFormatWithTime(callLog.booking.start) }}
                </span>
              </div>
              <div v-else>
                <span class="">
                  {{ callLog.serviceForm.stringID }} -
                  {{ callLog.serviceForm.customerName }}
                </span>
              </div>
            </b-td>
            <b-td>
              <div v-if="callLog.booking">
                <span class="">
                  Booking
                </span>
              </div>
              <div v-else>
                <span class="">
                  Service Form
                </span>
              </div>
            </b-td>
            <b-td>
              <div v-if="callLog.booking">
                <span class="">
                  {{ dateFormatWithTime(callLog.booking.createdAt) }}
                </span>
              </div>
              <div v-else>
                <span class="">
                  {{ dateFormatWithTime(callLog.serviceForm.createdAt) }}
                </span>
              </div>
            </b-td>
            <b-td>
              <div v-if="callLog.booking">
                <span class="">
                  {{ callLog.booking.dutyOfficer ? callLog.booking.dutyOfficer.name : '' }}
                </span>
              </div>
              <div v-else>
                <span class="">
                  {{ callLog.serviceForm.dutyOfficer ? callLog.serviceForm.dutyOfficer.name : '' }}
                </span>
              </div>
            </b-td>
            <b-td>
              <div v-if="callLog.booking">
                <b-dropdown
                  v-if="canViewThisAction('show', 'Booking') || canViewThisAction('update', 'CallLog')"
                  variant="link"
                  no-caret
                >

                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
                    />
                  </template>

                  <b-dropdown-item
                    v-if="canViewThisAction('show', 'Booking')"
                    :to="{ name: 'appointment-bookings-show', params: { id: callLog.booking._id || 0 } }"
                  >
                    <feather-icon icon="EyeIcon" />
                    <span class="align-middle ml-50">View Booking</span>
                  </b-dropdown-item>

                  <b-dropdown-item
                    v-if="canViewThisAction('update', 'CallLog')"
                    @click="detachEntity(callLog)"
                  >
                    <feather-icon icon="TrashIcon" />
                    <span class="align-middle ml-50">Detach From Booking</span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
              <div v-else>
                <b-dropdown
                  v-if="canViewThisAction('show', 'ServiceForm') || canViewThisAction('update', 'CallLog')"
                  variant="link"
                  no-caret
                >

                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
                    />
                  </template>

                  <b-dropdown-item
                    v-if="canViewThisAction('show', 'ServiceForm')"
                    :to="{ name: 'operation-service-forms-show', params: { id: callLog.serviceForm._id || 0 } }"
                  >
                    <feather-icon icon="EyeIcon" />
                    <span class="align-middle ml-50">View Service Form</span>
                  </b-dropdown-item>

                  <b-dropdown-item
                    v-if="canViewThisAction('update', 'CallLog')"
                    @click="detachEntity(callLog)"
                  >
                    <feather-icon icon="TrashIcon" />
                    <span class="align-middle ml-50">Detach From Service Form</span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-card>

    <b-modal
      id="modal-update-duty-officer"
      ref="update-status-modal"
      cancel-variant="flat-primary"
      ok-variant="success"
      ok-title="Update"
      cancel-title="Cancel"
      centered
      no-close-on-backdrop
      title="Update Duty Officer"
      @ok="submitForm"
    >
      <b-form @submit.prevent="submitForm">
        <validation-observer
          ref="callLogDutyOfficerEditForm"
        >
          <b-form-group
            label="Duty Officer*"
            label-for="h-activity-type-status"
          >
            <validation-provider
              #default="{ errors }"
              name="Duty Officer"
              vid="status"
              rules="required"
            >
              <v-select
                id="h-call-log-dutyOfficer"
                v-model="dutyOfficer"
                label="name"
                :options="dutyOfficerOptions"
                :reduce="name => name._id"
                :clearable="false"
                placeholder="Select an option"
                @input="dutyOfficerValidation == true ? dutyOfficerValidation = false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <small
                v-if="dutyOfficerValidation"
                class="text-danger"
              >
                {{ dutyOfficerError }}
              </small>
            </validation-provider>
          </b-form-group>
        </validation-observer>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BButton, BCol, BTableSimple, BThead, BTbody, BTr, BTh, BTd, BDropdown, BDropdownItem, BModal, BForm, BFormGroup,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import { required } from '@validations'
import { useUtils as useAclUtils } from '@/libs/acl/custom'

const { canViewThisAction } = useAclUtils()

export default {
  components: {
    BCard,
    BRow,
    BButton,
    BCol,
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
    BDropdown,
    BDropdownItem,
    BModal,
    BForm,
    BFormGroup,

    vSelect,

    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      canViewThisAction,
      callLog: {},
      callLogID: '',
      stringID: '',
      detachedStringID: '',
      dutyOfficer: '',
      type: '',
      dutyOfficerOptions: [],
      dutyOfficerValidation: false,
      dutyOfficerError: 'Valid duty officer is required',
      relatedBookingRecords: [],
      relatedServiceFormRecords: [],
      tableColumns: [
        {
          key: 'stringID',
          label: 'ID',
          sortable: true,
        },
        {
          key: 'booking',
          label: 'Record Name',
          sortable: true,
        },
        {
          key: 'typeOfCall',
          label: 'Activity Type',
          sortable: true,
        },
        {
          key: 'createdAt',
          label: 'created date',
          sortable: true,
        },
        {
          key: 'dutyOfficer.name',
          label: 'created by',
          sortable: true,
        },
        { key: 'actions' },
      ],
      // eslint-disable-next-line global-require
      defaultImage: require('@/assets/images/frontend/logo-nirvana-memorial-garden.86fe2ff5.svg'),

      required,
    }
  },
  created() {
    this.$http.get(`appointment/call-logs/${this.$route.params.id}/show`)
      .then(response => {
        this.callLog = response.data.data || {}
        this.dutyOfficerOptions = response.data.dutyOfficers || []

        this.setData()
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
  mounted() {
    window.scrollTo(0, 0)
  },

  methods: {
    setData() {
      this.callLogID = this.callLog._id

      this.dutyOfficer = this.callLog.dutyOfficer ? this.callLog.dutyOfficer._id : ''
      this.stringID = this.callLog.stringID
    },

    detachEntity(val) {
      if (val.booking) {
        this.type = 'booking'
      } else {
        this.type = 'service form'
      }
      this.detachedStringID = val.stringID
      this.$swal({
        title: 'Are You Sure?',
        html: `Selecting Detach will <strong>detach</strong> this item from ${this.type}.`,
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        reverseButtons: true,
        showCancelButton: true,
        cancelButtonText: 'No, Cancel',
        confirmButtonText: 'Yes, Detach!',
        customClass: {
          confirmButton: 'btn btn-danger ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            const formData = new FormData()
            formData.append('callLog', this.$route.params.id)
            formData.append('bookingID', this.callLog.booking)
            formData.append('serviceFormID', this.callLog.serviceForm)

            this.$http.patch(`appointment/call-logs/${val._id}/detach-from-booking`, formData, {
              headers: { 'Content-Type': 'multipart/form-data' },
            })
              .then(response => {
                this.callLog = response.data.data || {}

                this.$swal({
                  title: 'Call Log Detached!',
                  html: `Call Log <strong>${this.detachedStringID}</strong> has been detached from ${this.type}.`,
                  // eslint-disable-next-line global-require
                  imageUrl: require('@/assets/images/icons/save.png'),
                  imageWidth: 80,
                  imageHeight: 80,
                  imageAlt: 'Custom Icon',
                  showCancelButton: false,
                  confirmButtonText: 'Okay',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              })
              .catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              })
          }
        })
    },

    submitForm() {
      this.$refs.callLogDutyOfficerEditForm.validate().then(success => {
        if (success) {
          const formData = new FormData()
          formData.append('dutyOfficer', this.dutyOfficer)

          this.$http.patch(`appointment/call-logs/${this.callLogID}/duty-officer/update`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              this.callLog.dutyOfficer = response.data.data.dutyOfficer || {}
              this.setData()
              // this.$nextTick(() => {
              //   this.$refs['update-status-modal'].toggle('#toggle-btn')
              // })
              this.$swal({
                title: 'Duty Officer Updated!',
                html: `Duty Officer for <strong>${this.stringID}</strong> has been updated`,
                // eslint-disable-next-line global-require
                imageUrl: require('@/assets/images/icons/save.png'),
                imageWidth: 80,
                imageHeight: 80,
                imageAlt: 'Custom Icon',
                showCancelButton: false,
                confirmButtonText: 'Okay',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'dutyOfficer') {
                    this.dutyOfficerError = validationError.msg
                    this.dutyOfficerValidation = true
                  }
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
  },
}
</script>
